<canvas #canvas class="col-md-12" width="630" height="600px">
</canvas>


<div class="group-button responsive_search mt-2" class="clFlex">
    <button class="btn btn-soft-primary waves-effect waves-light me-2" (click)="zoomIn()" pTooltip="Zoom In"
    tooltipPosition="top">
        <i class="dripicons-zoom-in font-size-15 align-middle rotateninty"></i>
    </button>
    <button class="btn btn-soft-primary waves-effect waves-light me-2" (click)="zoomOut()" pTooltip="Zoom Out"
    tooltipPosition="top">
        <i class="dripicons-zoom-out font-size-15 align-middle rotateninty"></i>
    </button>
    <button class="btn btn-soft-primary waves-effect waves-light me-2" (click)="antiRotateImage()" pTooltip="Rotate"
    tooltipPosition="top">
        <i class="dripicons-clockwise font-size-15 align-middle antflip"></i>
    </button>
    <button class="btn btn-soft-primary waves-effect waves-light me-2" (click)="rotateImage()" pTooltip="Rotate"
    tooltipPosition="top">
        <i class="dripicons-clockwise font-size-15 align-middle rotateninty"></i>
    </button>
    <button class="btn btn-primary waves-effect waves-light" (click)="resetZoom()" pTooltip="Return"
    tooltipPosition="top">
        <i class="dripicons-return font-size-15 align-middle rotateninty"></i>
    </button>
</div>