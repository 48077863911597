import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { WorkspaceService } from '../core/services/workspace/workspace.service';
import { Globals } from '../globals/globals';
declare let toastr;

@Injectable()
export class WorkspaceResolve  {
  
  constructor(private workspaceService: WorkspaceService,
    public globals:Globals) {}
  
  resolve(route: ActivatedRouteSnapshot) {
	return this.getWorkspaces().toPromise()
			.then((res: any) => {
				if (res.flag) {
					this.globals.workspaceOptions = res.data;
					this.globals.WorkSpaceID = this.globals.workspaceOptions[0].workspace_guid;
					
					return this.workspaceService.getMyWorkspaceActions()
						.toPromise()
						.then((result: any) => {
							if (result.flag) {
								this.globals.workspaceActions = result.data.actions;
								this.globals.workspace_key = result.data.workspace_key;
							}
							else {
								this.globals.workspaceActions = [];
							}
							return true;
						})
						.catch((error: any) => {
							//Handle any errors that occurred during the API request
							console.error('Error fetching data:', error);
						});
				}
				else {
					this.globals.workspaceOptions = [];
					this.globals.workspaceActions = [];
				}
			})
			.catch((error: any) => {
				//Handle any errors that occurred during the API request
				console.error('Error fetching data:', error);
			});
	
  }


  getWorkspaces() {
    return this.workspaceService.getMyWorkspaces();
  }
}