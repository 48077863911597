import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Globals } from 'src/app/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public http: HttpClient, public globals: Globals) { }


  public getHeaderNotificationData(postData, silentRefresh:boolean = false): Observable<any> {
    // if (this.globals.workspaceActions.includes('Instance_View')) {
      if(silentRefresh){
        let par: HttpParams = new HttpParams().set("silentServerFetch", "1");
        return this.http.post(environment.serviceurl + '/Notifications/User', postData,{ params: par });
      }else {
        return this.http.post(environment.serviceurl + '/Notifications/User', postData);
      }
    // } else {
    //   return from([]);
    // }
  }

  public geUnreadNotificationCount(): Observable<any> {
    // if (this.globals.workspaceActions.includes('Instance_View')) {
      let par: HttpParams = new HttpParams().set("silentServerFetch", "1");
      return this.http.get(environment.serviceurl + '/Notifications/UserUnreadCount',{ params: par });
    // } else {
    //   return from([]);
    // }
  }


  public getNotificationById(notification_guid): Observable<any> {
    // if (this.globals.workspaceActions.includes('Instance_View')) {
      return this.http.get(environment.serviceurl + '/Notifications/' + notification_guid);
    // } else {
    //   return from([]);
    // }
  }

  public deleteNotificationById(notification_guids): Observable<any> {
    // if (this.globals.workspaceActions.includes('Instance_View')) {
      return this.http.post(environment.serviceurl + '/Notifications/MarkAsDelete', notification_guids);
    // } else {
    //   return from([]);
    // }
  }


  public markAsNotificationById(notification_guids): Observable<any> {
    // if (this.globals.workspaceActions.includes('Instance_View')) {
      return this.http.post(environment.serviceurl + '/Notifications/MarkAsRead', notification_guids);
    // } else {
    //   return from([]);
    // }
  }

  

}

