import { Inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, finalize, Observable, throwError as observableThrowError, of, tap, throwError, } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Globals } from 'src/app/globals/globals';
import { AuthenticationService } from '../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { TokenstorageService } from '../services/token-storage/tokenstorage.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
	public lastResponseCode: number = 0;
	constructor(private router: Router, public globals: Globals,
		private authStorage: OAuthStorage,
		private authService: AuthenticationService,
		private tokenStorageService: TokenstorageService,
		@Inject(Injector) private injector: Injector) { }
	private getTimezoneOffset(): string {
		return (String(new Date().getTimezoneOffset() * -1));
	}

	private get toastrService(): ToastrService {
		return this.injector.get(ToastrService);
	}


	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		let reqheaders: any;

		let par: any = request.params;
		let silentServerFetch: boolean = false;
		reqheaders = {
			"X-Timezone-Offset": this.getTimezoneOffset()
		}

		if (request.params.has("multiPartData")) {
			par = request.params.delete("multiPartData");
			reqheaders["enctype"] = "multipart/form-data";
		} else {
			if (request.url.startsWith(environment.serviceurl)) {
				reqheaders["Content-Type"] = "application/json";
			}
		}
		if (request.url.startsWith(environment.serviceurl)) {
			let token = this.authStorage.getItem('access_token');

			if (token != null && token != undefined) {
				reqheaders["Authorization"] = 'Bearer ' + token;
				this.tokenStorageService.setToken(token);
			}
		}

		if (request.url.startsWith(environment.serviceurl) && this.globals != undefined
			&& this.globals.WorkSpaceID != null && this.globals.WorkSpaceID != undefined &&
			this.globals.WorkSpaceID.length > 0) {
			reqheaders["workspaceid"] = this.globals.WorkSpaceID;
		}

		if (request.url.startsWith(environment.serviceurl) && this.globals != undefined
			&& this.globals.workspace_key != null && this.globals.workspace_key != undefined &&
			this.globals.workspace_key.length > 0) {
			reqheaders["workspace_key"] = this.globals.workspace_key;
		}


		if (request.url.startsWith(environment.serviceurl) && this.globals != undefined
			&& this.globals.TenantUniqueKey != null && this.globals.TenantUniqueKey != undefined &&
			this.globals.TenantUniqueKey.length > 0) {
			reqheaders["tenant_unique_key"] = this.globals.TenantUniqueKey;
		}


		request = request.clone({
			withCredentials: true,
			setHeaders: reqheaders,
			params: par
		});

		// this.toastrService.warning('Something went wrong at server.');
		// return next.handle(request).pipe(

		// 	tap((event: HttpEvent<any>) => {
		// 	  // Log successful responses here
		// 	  if (event instanceof HttpResponse) {
		// 	  }
		// 	}),
		// 	catchError((error: HttpErrorResponse) => {
		// 	  // Only log errors that are not 401 responses
		// 	  if (error.status !== 401) {
		// 		// console.error('Error:', error);
		// 	  }
		// 	  return observableThrowError(()=>error);
		// 	})
		//   );

		return next.handle(request).pipe(catchError(response => {

			if (response instanceof HttpErrorResponse) {
				if (this.lastResponseCode != response.status) {
					if (response.status == 350) {
						//login
						this.authService.logout();
						this.authService.configureOAuthAndLogin();
						//this.router.navigate(['/Identity/Account/Login']);
						//window.location.href = '/Identity/Account/Login';
						this.lastResponseCode = response.status;
					}
					else if (response.status == 401) {
						this.toastrService.warning("You are unauthorized to view this page");
						this.lastResponseCode = response.status;

					}
					else if (response.status == 500) {
						this.toastrService.warning('Something went wrong at server.');
						this.lastResponseCode = response.status;
					}
					else if (response.status == 352) {
						this.authService.logout();
						//this.router.navigate(['/Identity/Account/Login']);
						//window.location.href = '/Identity/Account/Login';
						this.authService.configureOAuthAndLogin();
						this.lastResponseCode = response.status;
					}
					else if (response.status == 429) {
						this.toastrService.warning('Too many requests. Please try again later.');
						this.lastResponseCode = response.status;
					}
				}
			}
			return throwError(() => response);
			//   return observableThrowError(response);
		}), tap((ev: HttpEvent<any>) => {
			if (ev instanceof HttpResponse) {
				this.lastResponseCode = ev.status;
				if (!silentServerFetch) {
				}
			}
		}),);


	}
}

@Injectable()
export class LoaderService implements HttpInterceptor {
	counter = 0;
	constructor(private spinner: NgxSpinnerService, private globals: Globals) { }
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (!req.params.has("silentServerFetch") && this.globals.spinnerAnimation && !req.url.endsWith("/Identity/connect/token")) {
			this.counter += 1;
			this.spinner.show();

			return next.handle(req).pipe(
				finalize(() => {
					this.counter -= 1;

					if (this.counter < 0)
						this.counter = 0;

					if (this.counter == 0)
						this.spinner.hide();
				})
			);
		} else {
			if (req.params.has("silentServerFetch")) {
				var par = req.params.delete("silentServerFetch");

				req = req.clone({
					withCredentials: true,
					params: par
				});
			}
			return next.handle(req);
		}

	}
}
