import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import MetisMenu from 'metismenujs';
import { Router, NavigationEnd } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { Globals } from 'src/app/globals/globals';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

/**
 * Sidebar Component
 */
export class SidebarComponent implements OnInit {
  @ViewChild('sideMenu') sideMenu!: ElementRef;

  queuesValue: boolean | undefined;

  constructor(
    private router: Router,
    private eventService: EventService,
    public globals: Globals
  ) {}

  ngOnInit(): void {}
  onQueuesList(value: boolean) {
    localStorage.setItem('queuesBtn', JSON.stringify(value));
    this.queuesValue = value;
    this.eventService.updateQueuesValue(this.queuesValue);
    if (value) {
      this.router.navigate([
        '/' +
          this.globals.TenantUniqueKey +
          '/cloudautomation' +
          '/' +
          'queues',
      ]);
    }
  }

  getClass()
  {
    if(this.router.url.endsWith("itempriority") ||this.router.url.endsWith("queues")||this.router.url.endsWith("customstatus"))
      return true;
  }
}
