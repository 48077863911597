
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './core/services/auth/auth.service';
import { Globals } from './globals/globals';
@Injectable()
export class AuthguardGuard  {
  flag: boolean = false;
  constructor(private authService: AuthenticationService, public globals: Globals, private router: Router) {
  }
  canActivate(a): Observable<boolean> {
    return this.authService.IsLoggedIn().pipe(map((response) => {
      if (response.flag) {
		  if(response.data.platformRole.includes("PlatformAdmin") || 
			response.data.platformRole.includes("PlatformUser")){
			this.flag = true;
			this.globals.isPlatformRole = true;
		  }
		  else{
			this.globals.isPlatformRole = false;
			if (a.routeConfig.path.includes("cloudautomation") || a.routeConfig.path.includes("master")) {
				if (response.data.platformRole.includes("TenantUser")) {
					this.flag = true;
				}
				else {
					this.authService.login();
					this.router.navigate(["/"]);
				}
			} else {
				this.authService.login();
				this.router.navigate(["/"]);
			}
			
		  }
      }
      else {
		  this.authService.login();
		  this.router.navigate(["/"]);
      }
      return this.flag;
    }));
  }
}
