import { Injectable } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';

@Injectable()
export class Globals {
	public isPlatformRole: boolean;
	public TenantUniqueKey: string = '';
	public userData: any;

	public WorkSpaceID: string = '';
	public workspace_key: string = '';
	public workspaceOptions: any[] = null;
	public workspaceActions: any[] = [];
	public FormID: string = '';
	
	public spinnerAnimation: boolean = true;

	public activeSidebars: any[] = [];
	sideBarName: any;

	public newSidebarOpen(_page, _sidebarvar) {
		this.sideBarName = _sidebarvar
		if (_sidebarvar == 'showAddJob') {
			document.body.classList.add('sidebar-open');
		}
		this.activeSidebars.push({ 'page': _page, 'var': _sidebarvar });
	}

	public sidebarClose(_page, _sidebarvar) {
		if (_sidebarvar == 'showAddJob') {
			document.body.classList.remove('sidebar-open');
		}
		this.activeSidebars.forEach((element, index) => {
			if (element.page == _page && element.var == _sidebarvar) this.activeSidebars.splice(index, 1);
		});
	}

	public closeActiveSidebar() {
		if (this.sideBarName == 'showAddJob') {
			document.body.classList.remove('sidebar-open');
		}
		if (this.activeSidebars.length > 0) {
			this.activeSidebars[this.activeSidebars.length - 1].page[this.activeSidebars[this.activeSidebars.length - 1].var] = false;
			this.activeSidebars.splice(this.activeSidebars.length - 1, 1);
		}
	}
}