import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenstorageService {
  private accessToken: string = '';  // Store token here

  constructor() {}

  setToken(token: string) {
    this.accessToken = token;  // Set the token
  }

  getToken(): string {
    return this.accessToken;  // Get the token
  }
}