import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { AlertConfigurationsService } from '../../core/services/alertconfigurations/alert-configurations.service';
import Swal from 'sweetalert2';
import { Globals } from 'src/app/globals/globals';


@Component({
  selector: 'alert-configurations',
  templateUrl: './alert-configurations.component.html',
  styleUrls: ['./alert-configurations.component.scss'],
  providers: [MessageService]
})
export class AlertConfigurationsComponent implements OnInit {
  @ViewChild('dataTable') dataTable: Table;
  @Input() getViewData: any;
  lastTableLazyLoadEvent: LazyLoadEvent;
  listAlertConfigurations: any;
  alertConfig: any = {
    "object_guid": null, "pageSize": null, "page": null, "sortDirection": null, "sortBy": null
  };
  totalRecords: any;
  showAlertConfigView: boolean;
  showAlertConfigAdd: boolean
  getViewAlertCofig: string;
  addAlertData: { object_guid: any; object_type: string; guId: any };
  goToPageValue: number;
  constructor(private alertConfigurationsService: AlertConfigurationsService, public globals: Globals,
    private messageService: MessageService,) { }

  ngOnInit(): void {

  }

  goToPage() {
    if (Math.ceil(this.dataTable.totalRecords / this.lastTableLazyLoadEvent.rows) >= this.goToPageValue && this.goToPageValue >= 1) {
      this.dataTable.first = this.lastTableLazyLoadEvent.rows * (this.goToPageValue - 1);
      this.lastTableLazyLoadEvent.first = this.lastTableLazyLoadEvent.rows * (this.goToPageValue - 1);
      this.getAlertConfigurations(this.lastTableLazyLoadEvent);
      this.goToPageValue = null;
    }
    else {
      this.goToPageValue = null;
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: "Invalid page number",
      });
    }

  }

  getAlertConfigurations(event: LazyLoadEvent) {
    window.scroll(0, 0);
    this.lastTableLazyLoadEvent = event;
    if (this.dataTable?.rowsPerPageOptions?.indexOf(event.rows) == -1) { this.alertConfig.pageSize = -1; }
    else
      this.alertConfig.pageSize = event.rows
    this.alertConfig.page = event.first / event.rows;
    this.alertConfig.sortBy = event.sortField;
    if (event.sortOrder == 1) {
      this.alertConfig.sortDirection = 'asc';
    } else {
      this.alertConfig.sortDirection = 'desc';
    }
    this.alertConfig.object_guid = this.getViewData.credential_guid;
    this.alertConfigurationsService.getAlertsConfig(this.alertConfig).subscribe((res) => {
      if (res.flag) {
        this.listAlertConfigurations = res.data;
        this.totalRecords = res.totalRows;
      }
    });
  }

  deleteAlertConfig(guId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.alertConfigurationsService.deleteAlertsConfig(guId).subscribe((res) => {
          if (res.flag) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
            this.dataTable.first = 0;
            this.dataTable.firstChange.emit(this.dataTable.first);
            this.dataTable.onLazyLoad.emit(this.dataTable.createLazyLoadMetadata());
            // this.onRefresh();
          }
          else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
          }
        });
      }
    });
  }

  onRefresh() {
    this.getAlertConfigurations(this.lastTableLazyLoadEvent)
  }

  openAdd(value) {
    this.showAlertConfigAdd = true;
    const data = { "object_guid": this.getViewData.credential_guid, "object_type": "Credential", "guId": value }
    this.addAlertData = data;
  }

  openView(guId) {
    this.getViewAlertCofig = guId;
    this.showAlertConfigView = true;
  }

  hideSidebar(value: boolean) {
    if (value) {
      this.showAlertConfigAdd = false;
      if (this.dataTable.totalRecords != this.dataTable.rows) {
        this.dataTable.first =
          Math.floor(this.dataTable.totalRecords / this.dataTable.rows) *
          this.dataTable.rows;
      } else {
        this.dataTable.first = 0;
        this.dataTable.rows = this.dataTable.rows + 1;
        this.dataTable.totalRecords = this.dataTable.totalRecords + 1;
        this.dataTable.rowsChange.emit(this.dataTable.rows);
      }
      this.dataTable.firstChange.emit(this.dataTable.first);
      this.dataTable.onLazyLoad.emit(this.dataTable.createLazyLoadMetadata());
    } else if (value == false) {
      this.onRefresh();
    }

    this.showAlertConfigAdd = false;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
