<div class="card">
  <div class="card-header">
    <h4 class="card-title">{{alertList?.object_type}} - Info</h4>
  </div>
  <div class="card-body">

    <div class="row">
      <div class="col-md-12">
        <div class="pb-3">
          <h5 class="font-size-15">Alert Mode</h5>
          <div class="text-muted">
            <p class="mb-2">{{alertList?.alert_mode_string}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="pb-3">
          <h5 class="font-size-15">Alert Type</h5>
          <div class="text-muted">
            <p class="mb-2">{{alertList?.alert_type_string}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="pb-3">
          <h5 class="font-size-15">Send To</h5>
          <div class="text-muted">
            <p class="mb-2">{{alertList?.send_to_value_text ? alertList?.send_to_value_text : alertList?.send_to_value}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="pb-3">
          <h5 class="font-size-15">Receiver To</h5>
          <div class="text-muted">
            <p class="mb-2">{{alertList?.send_to_value_type_string}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="pb-3">
          <h5 class="font-size-15">Effective From</h5>
          <div class="text-muted">
            <p class="mb-2">{{alertList?.effective_from | customDatetime}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="pb-3">
          <h5 class="font-size-15">Effective To</h5>
          <div class="text-muted">
            <p class="mb-2">{{alertList?.effective_till | customDatetime}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>