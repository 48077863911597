<app-pagetitle title="Alert Configuration"></app-pagetitle>
<p-toast position="top-right"></p-toast>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-12">
                        <div class="group-button responsive_search">
                            <button type="button" class="btn btn-soft-primary waves-effect waves-light"
                                pTooltip="Refresh Table" tooltipPosition="top" (click)="onRefresh()">
                                <i class="bx bx-sync font-size-20 align-middle rotateninty"></i>
                            </button>
                            <button type="button" class="btn btn-primary waves-effect waves-light ml-2 "
                            *ngIf="globals.workspaceActions.includes('AlertsConfig_Create')"
                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight" pTooltip="Add" tooltipPosition="top" (click)="openAdd(null)">
                                <i class="bx bx-plus font-size-20 align-middle label-icon"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <p-table #dataTable [value]="listAlertConfigurations" [lazy]="true" [rows]="10" [paginator]="true"
                    [rowsPerPageOptions]="[10,20,50,100,{ showAll: 'All' }]" [totalRecords]="totalRecords"
                    paginatorRight="true" [showCurrentPageReport]="false"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    styleClass="p-datatable-gridlines" (onLazyLoad)="getAlertConfigurations($event)">
                    <ng-template pTemplate="header" class="table-light">
                        <tr>

                            <th pSortableColumn="object_type">Object Type <p-sortIcon field="object_type"></p-sortIcon>
                            <th pSortableColumn="alert_type_string">Alert Type<p-sortIcon
                                    field="alert_type_string"></p-sortIcon>
                            <th pSortableColumn="alert_mode_string">Alert Mode<p-sortIcon
                                    field="alert_mode_string"></p-sortIcon>
                            <th pSortableColumn="send_to_value">Send To<p-sortIcon field="send_to_value"></p-sortIcon>
                            <th pSortableColumn="send_to_value_type_string">Receiver To<p-sortIcon
                                    field="send_to_value_type_string"></p-sortIcon>
                            <th class="fixed-width-end">Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list>
                        <tr data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft"
                            (dblclick)="openView(list.alertconfig_guid)">
                            <td>{{list.object_type}}</td>
                            <td>{{list.alert_type_string}}</td>
                            <td>{{list.alert_mode_string}}</td>
                            <td>{{list.send_to_value_text ? list.send_to_value_text : list.send_to_value}}</td>
                            <td>{{list.send_to_value_type_string}}</td>
                            <td>
                                <span class="me-2" pTooltip="Edit" tooltipPosition="top"
                                *ngIf="globals.workspaceActions.includes('AlertsConfig_Edit')"
                                    (click)="openAdd(list.alertconfig_guid)"
                                    >
                                    <i class="dripicons-document-edit align-middle font-size-12"></i>
                                </span>
                                <span class="me-2" pTooltip="Delete" tooltipPosition="top"
                                *ngIf="globals.workspaceActions.includes('AlertsConfig_Delete')"
                                    (click)="deleteAlertConfig(list.alertconfig_guid)">
                                    <i class="dripicons-trash align-middle font-size-12"></i>
                                </span>

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="paginatorleft" let-state>
                        <span class="responsive_pagnation">
                        Showing {{state.totalRecords < 1 ? 0 : state.first + 1}} to {{state.totalRecords> 0 ?
                            ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
                                state.totalRecords) : 0}} of {{state.totalRecords ? state.totalRecords : 0}} entries
                                <input type="number" pTooltip="Page Number" tooltipPosition="top" class="me-2 goPage"
                                    #fieldGoValue="ngModel" (keyup.enter)="goToPage()" min="1" (keypress)="numberOnly($event)"
                                    [(ngModel)]="goToPageValue">
                                <button class="btn me-2"
                                    [ngClass]="{'btn-primary': fieldGoValue.value, 'btn-soft-primary':!fieldGoValue.value}"
                                    pTooltip="Go to Page" tooltipPosition="top" (click)="goToPage()">Go</button>
                                </span>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="10">No Data found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div> <!-- end col -->
</div> <!-- end row -->


<!--alert-config View-->
<p-sidebar [(visible)]="showAlertConfigView" styleClass="responsive_sidebar_max widthtwetyfive"  position="right" [closeOnEscape]="false"  [baseZIndex]="3"  (onShow)="globals.newSidebarOpen(this,'showAlertConfigView')" (onHide)="globals.sidebarClose(this,'showAlertConfigView')" appendTo="body">
<view-alert-config *ngIf="showAlertConfigView" [getViewId]="getViewAlertCofig"></view-alert-config>
</p-sidebar>

<!--alert-config add-->
<p-sidebar [(visible)]="showAlertConfigAdd" styleClass="responsive_sidebar_max widthtwetyfive" position="right" [closeOnEscape]="false"  [baseZIndex]="3"  (onShow)="globals.newSidebarOpen(this,'showAlertConfigAdd')" (onHide)="globals.sidebarClose(this,'showAlertConfigAdd')" appendTo="body">
<add-alert-config *ngIf="showAlertConfigAdd" [getAddAlertData]="addAlertData" (closeSidebar)="hideSidebar($event)"></add-alert-config>
</p-sidebar>

