import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'generateInitial' })
export class generateInitialPipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value == null) {
            return ''
        }
        let words = value.split(" ");
        const firstLetter = words[0][0];
        const secondLetter = words[1][0];
        const data = firstLetter + secondLetter
        return data

    }
}
