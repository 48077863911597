import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './authguard.guard';
import { BlankComponent } from './blank/blank.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { WorkspaceResolve } from './shared/Workspace.resolve';

const appRoutes: Routes = [
  // {path: 'form', component: GenerateformurlComponent },
  {
    path: 'form',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: ':tenant-id/cloudautomation', canActivate: [AuthguardGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    resolve: { workspaceOptions: WorkspaceResolve }
  },
  // { path:'tenants',canActivate: [AuthguardGuard], loadChildren:'./master/master.module#MasterModule'},
  {
    path: 'platformadmin', canActivate: [AuthguardGuard],
    loadChildren: () => import('./platformadmin/platformadmin.module').then(m => m.PlatformadminModule)
  },
  { path: '', component: BlankComponent },
  { path: '**', component: NotFoundComponent }
];



@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
