<!-- <app-pagetitle title="Validation Station" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
<p-toast position="top-right"></p-toast>
<div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row" *ngIf="showLoading" class="loading-spinner">
                        Loading...
                    </div>
                    <div class="row" *ngIf="!showLoading">
                        <div class="col-md-1" *ngIf="model_type !== 4 || ![1, 2, 3, 4].includes(model_subtype)">
                            <ng-container *ngIf="thumbnails?.pages && !responseSingleValidation.data.is_file_deleted;else noPages">
                                <div *ngFor="let thumbnail of thumbnails?.pages" class="image-container"
                                    [class.active]="thumbnail === activeThumbnail"
                                    (click)="setActiveThumbnail(thumbnail)">
                                    <img [src]="thumbnail?.blob" class="warrior-image" />

                                </div>
                            </ng-container>
                            <ng-template #noPages>

                            </ng-template>
                        </div>
                        <div class="col-md-6" *ngIf="model_type !== 4 || ![1, 2, 3, 4].includes(model_subtype)">
                            <div class="text-container" class="borderone">
                                <ng-container *ngIf="thumbnails?.pages && !responseSingleValidation.data.is_file_deleted;else noPagesImg">
                                    <imagecanvas [imageUrl]="activeThumbnail?.blob"
                                        [boxes]="selectedData ? [selectedData] : []"
                                        [getBoxesForCanvas]="boxesForCanvas"></imagecanvas>
                                </ng-container>
                                <ng-template #noPagesImg>
                                    <p>No Data</p>
                                </ng-template>

                            </div>
                        </div>

                        <div class="col-md-7"
                            *ngIf="model_type == 4 && (model_subtype == 1 || model_subtype == 2 || model_subtype == 3 || model_subtype == 4)">
                            <ng-container *ngIf="inputText;else noInputText">
                                <div class="form-group">
                                    <label for="additionalInfo">Input Text</label>
                                    <textarea class="form-control" id="additionalInfo" rows="8"
                                        placeholder="Enter additional information here"
                                        [(ngModel)]="inputText"></textarea>
                                </div>
                            </ng-container>
                            <ng-template #noInputText>
                                No Data
                            </ng-template>
                        </div>

                        <div class="col-md-5">
                            <div class="text-container">
                                <validationresult *ngIf="hitValidationResultsPage" [getOtherTable]="sendOtherTable"
                                    [getProviderForPlayGround]="providerForPlayGround"
                                    [getOtherFieldData]="sendOtherFieldData" [getResultData]="sendResultData"
                                    [getResultTables]="sendResultTables" [getInputText]="inputText"
                                    [getModelType]="model_type" [getModelSubType]="model_subtype"
                                    [gettextResult]="sendtextResult" [getFieldResultData]="sendFieldResultData"
                                    [getIndex]="activeThumbnail" [sendPlayGroundLLMIcon]="getPlayGroundLLMIcon"
                                    [getCustomerModelGuid]="customerModelGuid" [modelIsFileDeleted]="model_isFileDeleted"
                                    [ValidationTaskStatus]="getValidationTaskStatus" [verifyLLM]="checkLLM"
                                    [getTranGuid]="getViewID" (sendEditedData)="getEditedData($event)" (sendFeedback)="getFeedback($event)"
                                    (sendCordtoImage)="handleGetCordtoImage($event)">
                                </validationresult>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>