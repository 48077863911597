<p-toast position="top-right"></p-toast>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-6 col-md-6" *ngIf="this.getMapKeyData.grid == 'othertable'">
            <div class="form-group mb-3">
              <p-dropdown [options]="otherTableList" [(ngModel)]="selectedTableList" appendTo="body" optionLabel="name"
                optionValue="name" placeholder="Select a Table" (onChange)="onTableListSelected()"></p-dropdown>
            </div>
          </div>
          <div class="col-xl-6 col-md-6">
            <div class="form-group mb-3">
              <p-dropdown [options]="fieldList" [(ngModel)]="selectedFieldList" appendTo="body" optionLabel="name"
                optionValue="name" placeholder="Select a Field"></p-dropdown>
                <div *ngIf="isFieldSelected" class="text-danger">
                  Please select a field.
                </div>
            </div>
          </div>
          <div class="col-xl-6 col-md-6">
            <div class="form-group mb-3" *ngIf="this.getMapKeyData.grid == 'table' || this.getMapKeyData.grid == 'othertable'">
              <input type="text" class="form-control" [(ngModel)]="tablekeyWord" placeholder="Enter Key Word"/>
            </div>
            <div *ngIf="isKeywordEntered" class="text-danger">
              Please enter a keyword.
            </div>
          </div>
          <div class="col-xl-3 col-md-3">
            <div class="form-group mb-3">
              <button type="button" class="btn btn-soft-primary" (click)="onAppend()">
                Append</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
