import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { InputText } from 'primeng/inputtext';
import { SidebarService } from 'src/app/core/services/sidebar/sidebar.service';
import { ValidationtaskService } from 'src/app/core/services/validationtask/validationtask.service';
import { Globals } from 'src/app/globals/globals';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'validationsstation',
  templateUrl: './validationsstation.component.html',
  styleUrls: ['./validationsstation.component.scss'],
  providers: [MessageService]

})
export class ValidationsstationComponent implements OnInit {
  @Input() getViewID: any;
  @Input() showLocalLoading: boolean = false;
  @Input() getValidationTaskStatus: string;
  @Input() tran_guid: string;
  @Input() getPlayGroundLLMIcon: any;
  @Input() type: any;
  @Input() customerModelGuid: any | null;
  @Input() checkLLM: string | null;
  @Output() closeSidebarTryNow = new EventEmitter();
  @Output() closeSidebar = new EventEmitter();
  @Output() closeSidebarJsonError = new EventEmitter();
  @Output() loadingCompleted = new EventEmitter();
  boxesForCanvas: any[] = [];

  breadCrumbItems: Array<{}>;
  thumbnails: any;
  selectedData: any;
  activeThumbnail: any | null = null;
  sendResultData: any;
  sendFieldResultData: any;
  sendResultTables: any;
  responseSingleValidation: any;
  sendOtherFieldData: any;
  sendOtherTable: any;
  showLoading: boolean = false;
  providerForPlayGround: any;
  inputText: any;
  model_type: number;
  model_subtype: number;
  sendtextResult: any;
  hitValidationResultsPage: boolean;
  model_isFileDeleted: boolean;



  constructor(private validationtaskService: ValidationtaskService,
    private messageService: MessageService,
    private sidebarService: SidebarService,
    private sanitizer: DomSanitizer,
    public globals: Globals) {
    this.breadCrumbItems = [
      { label: 'DashBoard' },
      { label: 'Instances', active: true },
    ];
  }

  ngOnInit(): void {
    this.showLoading = this.showLocalLoading;
    this.getAllVaildations();

  }

  getAllVaildations() {
    this.validationtaskService.getValidationById(this.getViewID, this.type, this.showLocalLoading ? true : false).subscribe((res) => {
      try {
        this.responseSingleValidation = res;
        this.hitValidationResultsPage = true;
        this.providerForPlayGround = this.responseSingleValidation.data.result ? this.responseSingleValidation.data.result.provider : null;
        let a: any = "";
        let jsonObject: any;

        if (res && res.data) {
          if (this.type == "transaction") {
            if (res.data.status == "InProgress") {
              setTimeout(() => {
                this.getAllVaildations();
              }, 3000);
              return;
            }
            else if (res.data.status == "Error") {
              try {
                this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message + ", please try again" });
                this.closeSidebarJsonError.emit(true);
              }
              catch (error) {
                this.messageService.add({ severity: 'warn', summary: 'Warning', detail: "Extraction failed, please try again" });
                setTimeout(() => {
                  this.closeSidebarJsonError.emit(true);
                }, 2000);
                return
              }
              this.showLoading = false;
              this.loadingCompleted.emit();
              return;
            }

            this.inputText = res.data.result.inputText;
            this.model_type = res.data.result.model_type;
            this.model_subtype = res.data.result.model_subtype;
            this.model_isFileDeleted = res.data.result.is_file_deleted;

            var parsedResult = JSON.parse(res.data.result.result);
            if (this.model_type == 4 && (this.model_subtype == 2 || this.model_subtype == 3 || this.model_subtype == 4)) {
              a = JSON.stringify(parsedResult.TextResult);
              this.sendtextResult = a;
              this.showLoading = false;
              this.loadingCompleted.emit();
              return;
            }
            else {
              a = JSON.stringify(parsedResult.DetailedResult);
            }
          }
          else {
            this.inputText = res.data.inputText;
            this.model_type = res.data.model_type;
            this.model_subtype = res.data.model_subtype;
            if (this.model_type == 4 && (this.model_subtype == 2 || this.model_subtype == 3 || this.model_subtype == 4)) {
              a = res.data.output_json ? res.data.output_json : res.data.input_json;
              this.sendtextResult = a;
            }
            else {
              a = res.data.output_json ? JSON.stringify(JSON.parse(res.data.output_json).DetailedResult) : res.data.input_json;
            }
          }
        }

        this.showLoading = false;
        this.loadingCompleted.emit();

        if (typeof a === 'string' && a.trim() !== "") {
          try {
            if (this.model_type == 4 && (this.model_subtype == 2 || this.model_subtype == 3 || this.model_subtype == 4)) {
              jsonObject = a;
            }
            else {
              jsonObject = JSON.parse(a);
            }
          } catch (e) {
            console.error('Error parsing JSON string:', e);
            jsonObject = null;
            this.closeSidebarJsonError.emit(true);
          }
        } else {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: "No valid JSON string to parse" });
          jsonObject = null; // or any default value
          this.closeSidebarJsonError.emit(true);
          return;
        }

        if ((this.model_type != 4 && (this.model_subtype !== 1 && this.model_subtype !== 2 && this.model_subtype !== 3 && this.model_subtype !== 4)) ||
          (this.model_type == 4 && this.model_subtype == 0)) {
          if (jsonObject && jsonObject.pages) {
            jsonObject.pages.forEach(page => {
              var filepath = null;
              if (this.type == "transaction") {
                filepath = res.data.result.filepaths?.find(fp => fp.name === page.Imagename);
              }
              else {
                filepath = res.data.filepaths?.find(fp => fp.name === page.Imagename);
              }

              if (filepath) {
                //page.blob = filepath.base64Data;
                const fileType = filepath.name.split('.').pop();

                if (fileType == 'jpeg') {
                  let objectURL = 'data:image/jpeg;base64,' + filepath.base64Data;
                  page.blob = objectURL;
                }
                else {
                  page.blob = filepath.base64Data;
                }
              }
            });
          } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: "JsonObject is null or does not have pages" });
            this.closeSidebarJsonError.emit(true);
            return
          }

          this.thumbnails = jsonObject;
          if (this.thumbnails.pages[0]) {
            this.setActiveThumbnail(this.thumbnails.pages[0]);
          }
          this.sendResultData = this.thumbnails.fieldData;
          this.sendResultTables = this.thumbnails.Tables ? this.thumbnails.Tables : null;
          this.sendOtherTable = this.thumbnails.OtherTables;
          this.sendOtherFieldData = this.thumbnails.OtherfieldData;
        }
        else {
          if (this.model_type == 4 && this.model_subtype == 1) {
            this.thumbnails = jsonObject;
            this.sendFieldResultData = this.thumbnails.fieldData;
            this.sendResultTables = this.thumbnails.Tables ? this.thumbnails.Tables : null;
            this.sendOtherTable = this.thumbnails.OtherTables;
            this.sendOtherFieldData = this.thumbnails.OtherfieldData;
          }
        }
      } catch (error) {
        console.error('Error parsing JSON string:', error);
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: "No valid JSON string to parse" });
        this.closeSidebarJsonError.emit(true);
      }
    });


  }

  setActiveThumbnail(selectedthumbnail: any): void {
    if (this.activeThumbnail !== selectedthumbnail) {
      var selectedIndex = selectedthumbnail.index
      this.selectedData = this.filterDataByPageIndex(selectedIndex);
      this.activeThumbnail = selectedthumbnail;
    }
  }

  filterDataByPageIndex(pageIndex: number): any[] {
    if (!this.thumbnails || !this.thumbnails.fieldData) {
      console.warn('Thumbnails data is not available');
      return [];
    }

    return Object.entries(this.thumbnails.fieldData)
      .filter(([key, value]: [string, any]) => value.page === pageIndex)
      .map(([key, value]) => value);
  }


  getEditedData(eventData: { data: any, value: any, textAnalysis: any }) {
    if (eventData.value) {
      if (eventData.textAnalysis == false) {
        if (this.thumbnails && this.thumbnails.fieldData && eventData.data && eventData.data.data) {

          this.thumbnails.OtherTables = eventData.data.othertable;
          eventData.data.data.forEach(editedItem => {
            const propName = editedItem.propName;
            const propValue = editedItem.propValue;

            if (this.thumbnails.fieldData.hasOwnProperty(propName)) {
              if (this.thumbnails.fieldData[propName].value !== propValue) {
                this.thumbnails.fieldData[propName].value = propValue;
              }
            }
          });

          eventData.data.otherFileds.forEach(editedItem => {
            const propName = editedItem.propName;
            const propValue = editedItem.propValue;

            if (this.thumbnails.OtherfieldData.hasOwnProperty(propName)) {
              if (this.thumbnails.OtherfieldData[propName].value !== propValue) {
                this.thumbnails.OtherfieldData[propName].value = propValue;
              }
            }
          });
        }
      }
     

      var jsonData = JSON.stringify(this.thumbnails)
      this.responseSingleValidation.data.output_json = eventData.textAnalysis == true ? eventData.data : jsonData;
      this.responseSingleValidation.data.status = 2;
    } else {
      this.responseSingleValidation.data.output_json = null;
      this.responseSingleValidation.data.status = 3;
      this.responseSingleValidation.data.status_name = "Rejected"
    }
    this.responseSingleValidation.data.file = null;
    this.validationtaskService.postValidationData(this.responseSingleValidation.data).subscribe((res) => {
      if (res.flag) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.closeSidebar.emit(true);
        }, 1000);
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
      this.sidebarService.setSidebarState(false);
    });
  }

  handleGetCordtoImage(data) {
    this.boxesForCanvas = data;
  }

  getFeedback(data) {
    this.thumbnails.OtherTables = data.othertable;
    data.data.forEach(editedItem => {
      const propName = editedItem.propName;
      const propValue = editedItem.propValue;

      if (this.thumbnails.fieldData.hasOwnProperty(propName)) {
        if (this.thumbnails.fieldData[propName].value !== propValue) {
          this.thumbnails.fieldData[propName].value = propValue;
        }
      }
    });

    data.otherFileds.forEach(editedItem => {
      const propName = editedItem.propName;
      const propValue = editedItem.propValue;

      if (this.thumbnails.OtherfieldData.hasOwnProperty(propName)) {
        if (this.thumbnails.OtherfieldData[propName].value !== propValue) {
          this.thumbnails.OtherfieldData[propName].value = propValue;
        }
      }
    });
    const modifieddata = {
      tables: this.thumbnails.Tables,
      fieldData: this.thumbnails.fieldData,
      otherfieldData: this.thumbnails.otherfieldData,
      otherTables: this.thumbnails.OtherTables
    }

    const finalData = {
      customer_model_guid: data.customer_model_guid,
      transaction_guid: this.tran_guid ? this.tran_guid : data.transaction_guid,
      modified_result: JSON.stringify({ detailedResult: modifieddata })
    }



    this.validationtaskService.getModelFeedBack(finalData).subscribe((res) => {
      if (res.flag) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.closeSidebarTryNow.emit(true);
        }, 1000);
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
      this.sidebarService.setSidebarState(true);
    });
  }
}


