<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/Automatr_icon.svg"  alt="Logo" class="logo_size"/>
          </span>
          <span class="logo-lg">
            <img src="assets/images/Automatr_logo.svg" class="logo_size" alt="Logo"/>
          </span>
        </a>        
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <div class="p-3 text-center" *ngIf="queuesCheck">
        <div role="group" class="btn-group">

          <label
            *ngIf="globals.workspaceActions.includes('Queue_View') && globals.workspaceActions.includes('QueuePriority_View') || globals.workspaceActions.includes('QueueCustomStatus_View')"
            for="btnradio1" class="btn btn-soft-light" [ngClass]="getClass('queue')" routerLink="queues"
            routerLinkActive="mm-active btn-selected">Queue</label>
          <div class="vr"
            *ngIf="globals.workspaceActions.includes('QueuePriority_View') && globals.workspaceActions.includes('Queue_View') || globals.workspaceActions.includes('QueueCustomStatus_View')">
          </div>
          <label
            *ngIf="globals.workspaceActions.includes('QueuePriority_View') && globals.workspaceActions.includes('Queue_View') || globals.workspaceActions.includes('QueueCustomStatus_View')"
            for="btnradio2" class="btn btn-soft-light" routerLink="itempriority"
            routerLinkActive="mm-active btn-selected">Item Priority</label>
          <div class="vr"
            *ngIf="globals.workspaceActions.includes('QueueCustomStatus_View') && globals.workspaceActions.includes('Queue_View') || globals.workspaceActions.includes('QueuePriority_View')">
          </div>
          <label for="btnradio3" class="btn btn-soft-light" routerLink="customstatus"
            routerLinkActive="mm-active btn-selected"
            *ngIf="globals.workspaceActions.includes('QueueCustomStatus_View') && globals.workspaceActions.includes('Queue_View') || globals.workspaceActions.includes('QueuePriority_View')">Custom
            Status</label>
        </div>
      </div>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block me-2">
        <div class="selectBox">
          <p-dropdown [options]="workspaceOptions" [(ngModel)]="globals.WorkSpaceID" optionLabel="workspace_name"
            optionValue="workspace_guid" (onChange)="workspaceChanged($event)"></p-dropdown>
        </div>
      </div>
      <div class="dropdown d-inline-block me-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon position-relative"
          id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          ngbDropdownToggle>
          <i class="dripicons-bell"></i>
          <span class="badge bg-danger rounded-pill">{{totalNotifications}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0"> Notifications </h6>
              </div>
              <div class="col-auto" class="unreadCl" *ngIf="listOfUnreadNotifications">
                <label class="unreadlable">Show unread</label>
                <p-inputSwitch [(ngModel)]="showUnread" (onChange)="onSwitchChange()"></p-inputSwitch>
              </div>
            </div>
          </div>
          <ng-container *ngIf="listOfUnreadNotifications;else noNewNotifications">
            <ngx-simplebar class="simpleBarCl">
              <ng-container *ngIf="isLoading; else content">
                <ng-container *ngFor="let _ of [1,2]">
                  <div class="skeCl">
                    <p-skeleton shape="circle" size="2rem" styleClass="mr-3 ml-2"></p-skeleton>
                    <div class="skeClP">
                      <p-skeleton width="100%" styleClass="mb-1"></p-skeleton>
                      <p-skeleton width="75%"></p-skeleton>
                    </div>
                  </div>                  
                </ng-container>
              </ng-container>
              <ng-template #content>
              <a class="text-reset notification-item cursor-pointer" *ngFor="let list of listOfUnreadNotifications"
              (click)="onSingleView(list.notification_guid)">

              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm align-self-center">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary"
                      [ngClass]="{'unRead': !list.is_read}"> {{list.title | generateInitial}} </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{list.title}}</h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1">{{list.body | truncatePipe:30}}</p>
                  </div>
                </div>
              </div>
            </a>
            </ng-template>

            </ngx-simplebar>
          </ng-container>
          <ng-template #noNewNotifications>
            <p class="text-muted text-truncate text-center">No Notifications</p>
          </ng-template>
          <div class="p-2 border-top d-grid" *ngIf="listOfUnreadNotifications">
            <a class="btn btn-sm btn-link font-size-14 text-center" (click)="onViewAllPageRoute()">
              <i class="mdi mdi-arrow-right-circle me-1"></i> <span>View All</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          ngbDropdownToggle>
          <p-avatar shape="circle">
            {{ avatarValue | uppercase }}
          </p-avatar>
          <span class="d-none d-xl-inline-block ms-1 fw-medium">{{
            profileName
            }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item cursor-pointer" (click)="onLogout()"><i
              class="bx bx-log-out-circle font-size-16 align-middle me-1"></i>
            Log Out</a>
        </div>
      </div>
    </div>
  </div>
</header>