import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { AlertConfigurationsService } from 'src/app/core/services/alertconfigurations/alert-configurations.service';
@Component({
  selector: 'add-alert-config',
  templateUrl: './add-alert-config.component.html',
  styleUrls: ['./add-alert-config.component.scss'],
  providers: [MessageService]
})
export class AddAlertConfigComponent implements OnInit {
  alertConfigForm: FormGroup;
  @Input() getAddAlertData: any;
  @Output() closeSidebar = new EventEmitter<boolean>();
  alertTypesList: any;
  alertModesList: any;
  alertSendToValueList: any;
  checkSendValueField: number;
  currentUserList: any;
  currentRolesList: any;
  title: string;
  btnTitile: string;
  alertInfoData: any;
  alertData = {
    "object_guid": null,
    "object_type": null,
    "alert_type": null,
    "alert_mode": null,
    "send_to_value": null,
    "send_to_value_type": null,
    effective_from: null,
    effective_till: null,

  }
  formSubmitted: boolean;
  disableSendTOvalue: boolean;
  tomorrow: Date;

  constructor(private alertConfigurationsService: AlertConfigurationsService,
    private formBuilder: FormBuilder, private messageService: MessageService) { }



  ngOnInit(): void {
    this.alertConfigForm = this.formBuilder.group({
      alertTypes: ['', [Validators.required]],
      alertModes: ['', [Validators.required]],
      alertSendToValue: ['', [Validators.required]],
      alertSendToValueText: ['', [Validators.required]],
      effectiveFrom: [''],
      effectiveTo: ['']
    });
    if (this.getAddAlertData.guId == null) {
      this.alertInfoData = {};
      this.title = "Add";
      this.btnTitile = this.title;
    } else {
      this.btnTitile = "Update";
      this.onEditAlert(this.getAddAlertData.guId);
    }
    this.alertTypes();
    this.alertModes();
    this.sendToValueTypes();
    this.setMinDate();
  }


  alertTypes() {
    this.alertConfigurationsService.getAlertTypes().subscribe((res) => {
      if (res.flag) {
        this.alertTypesList = res.data;
      }
    });
  }

  alertModes() {
    this.alertConfigurationsService.getAlertModes().subscribe((res) => {
      if (res.flag) {
        this.alertModesList = res.data;
      }
    });
  }


  sendToValueTypes() {
    this.alertConfigurationsService.getSendToValueTypes().subscribe((res) => {
      if (res.flag) {
        this.alertSendToValueList = res.data;
      }
    });
  }

  onSendValueChange(value) {
    if (value == 3) {
      this.alertConfigurationsService.currentWorkspaceUser().subscribe((res) => {
        if (res.flag) {
          this.currentUserList = res.data.filter((res) => res.email_confirmed == true)
          this.checkSendValueField = value;
        }
      });
    } else if (value == 2) {
      this.alertConfigurationsService.currentRoles().subscribe((res) => {
        if (res.flag) {
          this.currentRolesList = res.data;
          this.checkSendValueField = value;
        }
      });
    } else {
      this.checkSendValueField = value;
    }

    if (this.getAddAlertData.guId == null) {
      this.alertConfigForm.get('alertSendToValueText').setValue('');
    }
  }
  setMinDate() {
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate());
    this.tomorrow.setHours(0, 0, 0, 0);
  }

  onSubmit() {
    if (this.alertConfigForm.valid) {
      this.formSubmitted = false;
      this.alertData.object_guid = this.getAddAlertData.object_guid,
        this.alertData.object_type = this.getAddAlertData.object_type,
        this.alertData.alert_type = this.alertConfigForm.value.alertTypes,
        this.alertData.alert_mode = this.alertConfigForm.value.alertModes,
        this.alertData.send_to_value_type = this.alertConfigForm.value.alertSendToValue,
        this.alertData.send_to_value = this.alertConfigForm.value.alertSendToValueText,
        this.alertData.effective_from = moment(this.alertConfigForm.value.effectiveFrom).format('MM-DD-YYYY');
      this.alertData.effective_till = moment(this.alertConfigForm.value.effectiveTo).format('MM-DD-YYYY');
      this.alertData['alertconfig_guid'] = this.alertInfoData.alertconfig_guid;
      this.alertConfigurationsService.createAlert(this.alertData).subscribe((res) => {
        if (res.flag) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
          this.alertConfigForm.reset();
          setTimeout(() => {
            if (this.alertInfoData.flow_guid) {
              this.closeSidebar.emit(false);
            } else {
              this.closeSidebar.emit(true);
            }
          }, 1000);
        } else {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
        }
      });
    } else {
      this.formSubmitted = true;

    }

  }


  onEditAlert(guid) {
    this.alertConfigurationsService.getAlertsConfigById(guid).subscribe(res => {
      if (res.flag) {
        this.alertInfoData = res.data;
        this.title = this.alertInfoData.alert_type_string + "- Info";
        if (this.alertInfoData.alertconfig_guid == guid) {
          this.alertConfigForm.patchValue({
            alertTypes: this.alertInfoData.alert_type,
            alertModes: this.alertInfoData.alert_mode,
            alertSendToValue: this.alertInfoData.send_to_value_type,
            alertSendToValueText: this.alertInfoData.send_to_value,
            effectiveFrom: this.alertInfoData.effective_from ? moment(this.alertInfoData.effective_from).format('MM/DD/YYYY') : null,
            effectiveTo: this.alertInfoData.effective_till ? moment(this.alertInfoData.effective_till).format('MM/DD/YYYY') : null,
          });
          this.checkSendValueField = this.alertInfoData.send_to_value_type;
          this.onSendValueChange(this.checkSendValueField)
        }
        else {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Something went wrong' });
        }
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
    });
  }

  reset() {
    this.alertConfigForm.reset();
    this.formSubmitted = false;
  }

  closeCanvas() {
    this.closeSidebar.emit(null);
  }

  onModeChange(value) {
    if (value == 4) {
      this.alertConfigForm.get('alertSendToValue').setValue(1);
      this.checkSendValueField = 1;
      this.disableSendTOvalue = true;
    } else {
      this.alertConfigForm.get('alertSendToValue').setValue('');
      this.checkSendValueField = 0;
      this.disableSendTOvalue = false;
    }
  }

}
