import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationtaskService } from 'src/app/core/services/validationtask/validationtask.service';
import { MessageService } from 'primeng/api';

interface ChatMessage {
  text: string;
  sender: 'user' | 'bot';
}


@Component({
  selector: 'playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
  providers: [MessageService]
})
export class PlaygroundComponent implements OnInit {
  @Input() getPropertyName: string;
  @Input() sendCustomerModelGuid: any | null;
  @Input() tranGuid: any;
  @Input() onProviderForPlayGround: number;
  @Output() closeSidebar = new EventEmitter();
  messages: ChatMessage[] = [];
  inputText: string = '';
  filterFile: any;
  valueSelected: boolean;
  messageSelected: any;
  showConfirmDocuments: boolean;
  checkedValue: boolean = true;
  classificationKeywords: string;
  checkBoxChecked: any = true;
  constructor(private validationtaskService: ValidationtaskService, private messageService: MessageService) { }
  ngOnInit(): void {
    console.log(this.onProviderForPlayGround, "onProviderForPlayGround")
  }

  // onUpload(event) {
  //   if (event.files && event.files.length > 0) {
  //     const [file] = event.files;
  //     const fileExtension = file.name.split('.').pop().toLowerCase();

  //     if (['pdf', 'png', 'jpg', 'doc', 'docx'].includes(fileExtension)) {
  //       this.filterFile = file;
  //     } else {
  //       this.messageService.add({ severity: 'warn', summary: 'Warning', detail: "Please select a valid pdf/image/document." });
  //     }
  //   }
  // }

  sendMessage() {
    if (!this.inputText.trim()) {
      return;
    }

    this.messages.push({ text: this.inputText, sender: 'user' });
    const data = {
      "prompt": this.inputText,
      "provider": this.onProviderForPlayGround,
      "transaction_guid": this.tranGuid,
      "fieldName": this.getPropertyName
    }
    this.validationtaskService.getPlayGroundChatResponse(data).subscribe((response) => {
      if (response.flag) {
        const value = response.data;
        this.messages.push({ text: value, sender: 'bot' });
      }
    });
    this.inputText = '';

    // } else {
    //   this.messageService.add({ severity: 'warn', summary: 'Warning', detail: "Please select a file above." });
    // }

  }

  onCheckboxChange() {
    this.showConfirmDocuments = true;
  }

  onCheckConfirm(event) {
    console.log(event.checked)
    this.checkBoxChecked = event.checked;


  }

  apiCall() {
    const data: any = {
      "customermodel_guid": this.sendCustomerModelGuid,
      "field": this.getPropertyName,
      "order": 2,
      "prompt_text": this.messageSelected,
    }
    if (!data.prompt_text) {
      return
    }
    if (this.checkBoxChecked == false) {
      if (this.classificationKeywords) {
        data.classification_keywords = this.classificationKeywords;
      } else {
        return
      }
    }
    console.log(data)

    this.validationtaskService.postPlayGroundChatResponse(data).subscribe((res) => {
      if (res.flag) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.closeSidebar.emit(true);
        }, 1500);
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
    });
  }
}




