import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Globals } from 'src/app/globals/globals';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertConfigurationsService {

  constructor(public http: HttpClient, public globals: Globals) { }

  public getAlertsConfig(postData): Observable<any> {
    if (this.globals.workspaceActions.includes('AlertsConfig_View')){
    return this.http.post(environment.serviceurl + '/AlertsConfig/search', postData);
    }else{
      return from([]);
    }
  }

  public deleteAlertsConfig(guId): Observable<any> {
    if (this.globals.workspaceActions.includes('AlertsConfig_Delete')){
    return this.http.delete(environment.serviceurl + '/AlertsConfig/' + guId);
    }else{
      return from([]);
    }
  }

  public getAlertsConfigById(guId): Observable<any> {
    if (this.globals.workspaceActions.includes('AlertsConfig_Edit') || this.globals.workspaceActions.includes('AlertsConfig_View')){
    return this.http.get(environment.serviceurl + '/AlertsConfig/' + guId);
    }else{
      return from([]);
    }
  }

  public getAlertTypes(): Observable<any> {
    // if (this.globals.workspaceActions.includes('Bot_View')){
    return this.http.get(environment.serviceurl + '/AlertTypes');
    // }else{
    //   return from([]);
    // }
  }

  public getAlertModes(): Observable<any> {
    // if (this.globals.workspaceActions.includes('Bot_View')){
    return this.http.get(environment.serviceurl + '/AlertModes');
    // }else{
    //   return from([]);
    // }
  }

  public getSendToValueTypes(): Observable<any> {
    // if (this.globals.workspaceActions.includes('Bot_View')){
    return this.http.get(environment.serviceurl + '/SendToValueType');
    // }else{
    //   return from([]);
    // }
  }

  public currentWorkspaceUser(): Observable<any> {
    // if (this.globals.workspaceActions.includes('Bot_View')){
    return this.http.get(environment.serviceurl + '/Workspace/CurrentWorkspaceUsers');
    // }else{
    //   return from([]);
    // }
  }

  public currentRoles(): Observable<any> {
    // if (this.globals.workspaceActions.includes('Bot_View')){
    return this.http.get(environment.serviceurl + '/CurrentRoles');
    // }else{
    //   return from([]);
    // }
  }

  public createAlert(data): Observable<any> {
    if (this.globals.workspaceActions.includes('AlertsConfig_Create') || this.globals.workspaceActions.includes('AlertsConfig_Edit')){
    return this.http.post(environment.serviceurl + '/AlertsConfig', data);
    }else{
      return from([]);
    }
  }


}
