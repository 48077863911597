import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { CustomDatetimePipe } from '../core/pipes/custom-datetime.pipe';
import { OnlyStringPipe } from '../core/pipes/onlystring/only-string.pipe';
import { NotApplicablePipe } from '../core/pipes/NA/not-applicable.pipe';
import { UTCLocalDateFormatPipe } from '../core/pipes/utclocal-date-format.pipe';
import { KeyPipe } from '../core/pipes/key/key.pipe';
import { KeysValuePipe } from '../core/pipes/keyvalues/keyValues.pipe';
import { AlertConfigurationsComponent } from './alert-configurations/alert-configurations.component';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectModule } from 'primeng/multiselect';
import { AddAlertConfigComponent } from './alert-configurations/add-alert-config/add-alert-config.component';
import { ViewAlertConfigComponent } from './alert-configurations/view-alert-config/view-alert-config.component';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TruncateStringPipe } from '../core/pipes/truncateString/truncateString..pipe';
import { generateInitialPipe } from '../core/pipes/generateInitials/generateInitial.pipe';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { ValidationsstationComponent } from './validationsstation/validationsstation.component';
import { ImagecanvasComponent } from './imagecanvas/imagecanvas.component';
import { ValidationresultComponent } from './validationresult/validationresult.component';
import { PlaygroundComponent } from './validationresult/playground/playground.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';

import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MapkeyComponent } from './validationresult/mapkey/mapkey.component';
import { SplitterModule } from 'primeng/splitter';
import { CapitalizePipe } from '../core/pipes/capitalizeFirst/capitalize.pipe';
@NgModule({
  declarations: [
    PagetitleComponent,
    CustomDatetimePipe,
    UTCLocalDateFormatPipe,
    OnlyStringPipe,
    NotApplicablePipe,
    TruncateStringPipe,
    CapitalizePipe,
    KeyPipe,
    generateInitialPipe,
    KeysValuePipe,
    AlertConfigurationsComponent,
    AddAlertConfigComponent,
    ViewAlertConfigComponent,
    ValidationsstationComponent,
    ImagecanvasComponent,
    ValidationresultComponent,
    PlaygroundComponent,
    MapkeyComponent,

  ],
  imports: [
    CommonModule,
    ButtonModule,
    PaginatorModule,
    RadioButtonModule,
    ToastModule,
    MessageModule,
    TooltipModule,
    TableModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    MultiSelectModule,
    SidebarModule,
    InputTextareaModule,
    CalendarModule,
    SimplebarAngularModule,
    FileUploadModule,
    CheckboxModule,
    InputSwitchModule,
    InputTextModule,
    AccordionModule,
    SplitterModule
  ],
  exports: [PagetitleComponent,
    CustomDatetimePipe,
    UTCLocalDateFormatPipe,
    OnlyStringPipe,
    NotApplicablePipe,
    TruncateStringPipe,
    KeyPipe,
    CapitalizePipe,
    generateInitialPipe,
    KeysValuePipe,
    AlertConfigurationsComponent,
    AddAlertConfigComponent,
    ViewAlertConfigComponent,
    ValidationsstationComponent,
    ImagecanvasComponent,
    ValidationresultComponent,
    SplitterModule
  ]
})
export class SharedModule { }
