import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, from, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Globals } from 'src/app/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class ValidationtaskService {
  constructor(public http: HttpClient, public globals: Globals) { }
  public getInstances(postData): Observable<any> {
    return this.http.post(environment.serviceurl + '/ValidationTask/search', postData);
  }

  public getValidationById(id, type, silentRefresh: boolean = false): Observable<any> {
	if (silentRefresh)
	{
		let par: HttpParams = new HttpParams().set("silentServerFetch", "1");
    
		if (type == "transaction")
		  return this.http.get(environment.serviceurl + '/Transactions/' + id, { params: par });
		else
		  return this.http.get(environment.serviceurl + '/ValidationTask/' + id, { params: par });
	}
	else
	{
		if (type == "transaction")
		  return this.http.get(environment.serviceurl + '/Transactions/' + id);
		else
		  return this.http.get(environment.serviceurl + '/ValidationTask/' + id);
	}
  }
  public postValidationData(data): Observable<any> {
    console.log(data,"api")
    return this.http.post(environment.serviceurl + '/ValidationTask/ValidateTask', data);
  }

  public getDynamicInstanceTypes(): Observable<any> {
    return this.http.get(environment.serviceurl + '/DynamicInstanceTypes');
  }

  public getPlayGroundChatResponse(data): Observable<any> {
    // let par: HttpParams = new HttpParams().set("multiPartData", "1");
    return this.http.post(environment.serviceurl + '/DataExtraction/ExtractForLLM', data);
  }

  public postPlayGroundChatResponse(data): Observable<any> {

    return this.http.post(environment.serviceurl + '/CustomPrompts', data);
  }

  public getFieldList(data): Observable<any> {

    return this.http.post(environment.serviceurl + '/CustomerModel/GetFieldList', data);
  }

  public postAppendData(data): Observable<any> {

    return this.http.post(environment.serviceurl + '/CustomerModel/AppendFieldKeyWord', data);
  }

  public getTableList(data): Observable<any> {

    return this.http.post(environment.serviceurl + '/CustomerModel/GetTableList', data);
  }

}
