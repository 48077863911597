import { Component, OnInit,HostListener  } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { JwksValidationHandler, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { authConfig } from '../app/core/auth/auth.config';
import { AuthenticationService } from './core/services/auth/auth.service';
import { Globals } from './globals/globals';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private _router: Router,
		private oauthService: OAuthService, public globals: Globals
		,private route: ActivatedRoute, private authStorage: OAuthStorage
		,private authService:AuthenticationService
		,private spinner: NgxSpinnerService) {
	this.globals.spinnerAnimation = false;
	this.spinner.show();
	
	localStorage.removeItem("queuesBtn");
  }
  
  params:any;
  
  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeydown(event: KeyboardEvent): void {
	this.globals.closeActiveSidebar();
  }
  
  ngOnInit(): void {
	let token = this.authStorage.getItem('access_token');
	
	let tenantUniqueKey = this.authStorage.getItem('TenantUniqueKey');
	
	
	this.route.queryParams.subscribe(params=>{
	
		this.params = params;
		if (params['TenantUniqueKey'] != null && params['TenantUniqueKey'] != undefined){
			this.globals.TenantUniqueKey=params['TenantUniqueKey'];
			this.authStorage.setItem('TenantUniqueKey',this.globals.TenantUniqueKey);
		}
		else{
		}
	});
	
	this._router.events.subscribe(event => {
	  if (event instanceof NavigationStart) {
		console.log('Navigation Start:', event.url);
	  } else if (event instanceof NavigationEnd) {
		console.log('Navigation End:', event.url);
		// Get the current value of the query params
		const queryPrms = this.route.snapshot.queryParams;
		
		if ('formid' in queryPrms){
			this.globals.FormID = queryPrms['formid'];
		}
		
		if (event.url.split('?')[0].split('#')[0] == "/form")
		{
			if ('id' in queryPrms){
				this.globals.FormID = queryPrms['id'];
			}
			
			this.authService.configureOAuth();
		}
		else {		
			if (!this.authService.IsLoggedInOIDC()) {
				this.authService.configureOAuthAndLogin();
				
				// if (formpage){
					// if ('id' in queryPrms){
						// this.authService.configureOAuthAndLogin({
							// skiptenantselection: 1,
							// formid: queryPrms['id']
						// });
					// }
					// else{
						// //Write code to navigate to form component & show invalid formid
						// this._router.navigate(['form'], {
						  // queryParams: { error: "InvalidFormID" }
						// });
					// }
				// }
				// else{
					// this.authService.configureOAuthAndLogin();
				// }
			}
			else{
				this.authService.configureOAuth();
			}
		}
	  }
	});
	
	this.oauthService.events.subscribe(event => {
		console.log('OAuth Event Type:',event.type);
		if (event.type === 'token_received'){
			if (this.globals.userData == undefined ||
				this.globals.userData == null){
				this.oauthService.loadUserProfile().then(() => {
					// Using the loaded user data
					let claims = this.oauthService.getIdentityClaims();
					if (claims){
						this.globals.userData = claims;
					}
					
					// for reading state variables
					// let state = this.oauthService.state;
					  // if (state) {
						// state=decodeURIComponent(decodeURIComponent(state));
						
						 // const result: { [key: string]: string } = {};
						  // const pairs = state.split(';');
						  // pairs.forEach(pair => {
							// const [key, value] = pair.split(':');
							// result[decodeURIComponent(key)] = decodeURIComponent(value);
							// console.log(key,':', value);
						  // });
					  // } else {
						// console.log('No state parameter was passed.');
					  // }
				});
			}
		}
		else if (event.type === 'user_profile_loaded'){
			if (!this.globals.FormID){
				// Get the current value of the query params
				const queryPrms = this.route.snapshot.queryParams;
				
				if ('formid' in queryPrms){
					this.globals.FormID = queryPrms['formid'];
				}
			}
			
			let urlPath = this._router.url.split('?')[0].split('#')[0];
		
			if (this.globals.TenantUniqueKey != null &&
				this.globals.TenantUniqueKey != undefined &&
				this.globals.TenantUniqueKey != ''){
				this._router.navigate(['/' + this.globals.TenantUniqueKey + '/cloudautomation']);
			}
			else if (urlPath != "/form")
			{
				if (this.globals.FormID){
					this._router.navigate(['form'], {
					  queryParams: { id: this.globals.FormID }
					});
				}
				else
				{
					this._router.navigate(['platformadmin'])
				}
			}
		}
		else if (event.type === 'token_refresh_error' || event.type === 'invalid_nonce_in_state'){
			this.authService.login();
		} else if (event.type === 'discovery_document_loaded'){
			if (token != null && token != undefined){
				this.globals.TenantUniqueKey = tenantUniqueKey;
				if (this.globals.userData == undefined ||
					this.globals.userData == null){
					this.oauthService.loadUserProfile().then(() => {
						let claims = this.oauthService.getIdentityClaims();
						if (claims){
							this.globals.userData = claims;
						}
					}).catch((err => {
					}));
				}
			}
		}
	});
  }
}