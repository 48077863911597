import { Component, Input, OnInit } from '@angular/core';
import { AlertConfigurationsService } from 'src/app/core/services/alertconfigurations/alert-configurations.service';

@Component({
  selector: 'view-alert-config',
  templateUrl: './view-alert-config.component.html',
  styleUrls: ['./view-alert-config.component.scss']
})
export class ViewAlertConfigComponent implements OnInit{
  @Input() getViewId: string;
  alertList: any;
  constructor(private alertConfigurationsService: AlertConfigurationsService) {}
  ngOnInit(): void {
    this.getAlertConfig();
  }

  getAlertConfig(){
    this.alertConfigurationsService.getAlertsConfigById(this.getViewId).subscribe((res)=>{
      if(res.flag){
       this.alertList = res.data;
      }
    });
  }

}
