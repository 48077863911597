<p-toast position="top-right"></p-toast>
<div class="card">
    <div class="card-header">
        <h4 class="card-title">{{title}}</h4>
    </div>
    <div class="card-body">
        <div>
            <form novalidate="" class="ng-untouched ng-pristine ng-invalid" [formGroup]="alertConfigForm"
                (ngSubmit)="onSubmit()">
                <input type="hidden">
                <div class="row">
                    <div class="col-xl-12 col-md-12">
                        <div class="form-group mb-3">
                            <label>Alert Types<span class="required"> *</span></label>
                            <p-dropdown [options]="alertTypesList" formControlName="alertTypes"
                                placeholder="Select a Type" optionLabel="name" optionValue="id" [ngClass]="{
                                    'ng-dirty':
                                      formSubmitted &&
                                      alertConfigForm.get('alertTypes').hasError('required')
                                  }"></p-dropdown>
                            <span *ngIf="formSubmitted && alertConfigForm.get('alertTypes').hasError('required')"
                                class="required">Please select a Type</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-md-12">
                        <div class="form-group mb-3">
                            <label>Modes <span class="required">*</span></label><br>
                            <div *ngFor="let category of alertModesList" class="field-checkbox radio-group">
                                <p-radioButton styleClass="me-1" [inputId]="category.key" [value]="category.id"
                                    formControlName="alertModes" (onClick)="onModeChange($event.value)"></p-radioButton>
                                <label [for]="category.key" class="mb-0">{{ category.name }}</label>
                            </div>
                            <div *ngIf="formSubmitted && alertConfigForm.get('alertModes').hasError('required')"
                                class="required">Please select a Mode</div>

                        </div>
                    </div>
                    <div class="col-xl-12 col-md-12">
                        <div class="form-group mb-3">
                            <label>Receiver To <span class="required">*</span></label>
                            <p-dropdown [options]="alertSendToValueList" [disabled]="disableSendTOvalue"
                                formControlName="alertSendToValue" (onChange)="onSendValueChange($event.value)"
                                placeholder="Select a value" optionLabel="name" optionValue="id" [ngClass]="{
                                    'ng-dirty':
                                      formSubmitted &&
                                      alertConfigForm.get('alertSendToValue').hasError('required')
                                  }"></p-dropdown>
                            <span *ngIf="formSubmitted && alertConfigForm.get('alertSendToValue').hasError('required')"
                                class="required">Please select a Receiver To</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-md-12" *ngIf="checkSendValueField == 3">
                        <div class="form-group mb-3">
                            <label>Users<span class="required">*</span></label>
                            <p-dropdown [options]="currentUserList" formControlName="alertSendToValueText"
                                placeholder="Select a User" optionLabel="user_email" optionValue="user_guid" [ngClass]="{
                                    'ng-dirty':
                                      formSubmitted &&
                                      alertConfigForm.get('alertSendToValueText').hasError('required')
                                  }"></p-dropdown>
                            <span
                                *ngIf="formSubmitted && alertConfigForm.get('alertSendToValueText').hasError('required')"
                                class="required">Please select a User</span>
                        </div>
                    </div>

                    <div class="col-xl-12 col-md-12" *ngIf="checkSendValueField == 2">
                        <div class="form-group mb-3">
                            <label>Roles<span class="required">*</span></label>
                            <p-dropdown [options]="currentRolesList" formControlName="alertSendToValueText"
                                placeholder="Select a Role" optionLabel="role_name" optionValue="role_guid" [ngClass]="{
                                    'ng-dirty':
                                      formSubmitted &&
                                      alertConfigForm.get('alertSendToValueText').hasError('required')
                                  }"></p-dropdown>
                            <span
                                *ngIf="formSubmitted && alertConfigForm.get('alertSendToValueText').hasError('required')"
                                class="required">Please select a Role</span>
                        </div>
                    </div>

                    <div class="col-xl-12 col-md-12" *ngIf="checkSendValueField == 1">
                        <div class="form-group mb-3">
                            <label>Free Text<span class="required">*</span></label><br>

                            <textarea rows="5" pInputTextarea formControlName="alertSendToValueText"></textarea>
                            <span
                                *ngIf="formSubmitted && alertConfigForm.get('alertSendToValueText').hasError('required')"
                                class="required">Please Enter Free Text</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-md-12">
                        <div class="form-group mb-3">
                            <label>Effective From</label>
                            <p-calendar formControlName="effectiveFrom" [minDate]="tomorrow"></p-calendar>
                        </div>
                    </div>
                    <div class="col-xl-12 col-md-12">
                        <div class="form-group mb-3">
                            <label>Effective To</label>
                            <p-calendar formControlName="effectiveTo" [minDate]="tomorrow"></p-calendar>
                        </div>
                    </div>

                </div>
                <div class="mt-4">
                    <div class="group-button">
                        <button type="submit" class="btn btn-primary waves-effect waves-light me-2">
                            {{btnTitile}}
                        </button>
                        <button type="button" class="btn btn-soft-light waves-effect waves-light me-2"
                            *ngIf="!getAddAlertData.guId" (click)="reset()">
                            Reset
                        </button>
                        <button type="button" class="btn btn-soft-light waves-effect waves-light"
                            (click)="closeCanvas()">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>