import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'imagecanvas',
  templateUrl: './imagecanvas.component.html',
  styleUrls: ['./imagecanvas.component.scss']
})
export class ImagecanvasComponent implements AfterViewInit, OnChanges {
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  private image = new Image();
  private initialScaleFactor = 1;
  private scaleFactor = 1;
  private lastMousePosition: { x: number, y: number } = { x: 0, y: 0 };
  private isDragging = false;
  private cameraZoom = 1;
  private MAX_ZOOM = 5;
  private MIN_ZOOM = 0.1;

  private cameraOffset = { x: 0, y: 0 };
  private dragStart = { x: 0, y: 0 };
  private rotationAngle = 0;

  @Input() imageUrl: string;
  @Input() boxes: any[] = [];

  @Input() getBoxesForCanvas: any;

  constructor() {
    this.image.crossOrigin = 'Anonymous';
    this.image.onload = () => {
      if (this.canvas && this.canvas.nativeElement) {
        this.initialScaleFactor = this.calculateScaleFactor(this.image.width, this.image.height, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.drawCanvas();
      }
    };
  }

  ngAfterViewInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.loadAndDrawImage();
    this.canvas.nativeElement.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.canvas.nativeElement.addEventListener('mouseup', this.onMouseUp.bind(this));
    this.canvas.nativeElement.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.canvas.nativeElement.addEventListener('wheel', this.onMouseWheel.bind(this), { passive: false });

  }

  onMouseWheel(event: WheelEvent): void {
    event.preventDefault(); // Prevent the page from scrolling

    const zoomIntensity = 0.1;
    const wheelDelta = event.deltaY;
    let zoomFactor = Math.exp(wheelDelta * zoomIntensity * -0.01);

    // Calculate the new zoom
    let newScaleFactor = this.scaleFactor * zoomFactor;
    // Apply constraints to the zoom level
    newScaleFactor = Math.min(Math.max(newScaleFactor, this.MIN_ZOOM), this.MAX_ZOOM);

    // Adjust offset to zoom towards the cursor position
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    const offsetX = (mouseX - this.lastMousePosition.x) * (zoomFactor - 1);
    const offsetY = (mouseY - this.lastMousePosition.y) * (zoomFactor - 1);

    this.lastMousePosition.x -= offsetX;
    this.lastMousePosition.y -= offsetY;
    this.scaleFactor = newScaleFactor;

    this.drawCanvas();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageUrl) {
      this.loadAndDrawImage();
    }

    if (changes.boxes && this.ctx) {
      this.drawCanvas();
    }
    if (changes.getBoxesForCanvas) {
      this.drawBoxes()
    }
    if ((changes.boxes || changes.getBoxesForCanvas) && this.ctx) {
      this.drawCanvas(); // Clears and draws the image, then calls drawBoxes
    }
console.log(this.getBoxesForCanvas)
  }

  private loadAndDrawImage(): void {
    if (this.canvas && this.canvas.nativeElement) {
      this.image.src = this.imageUrl;
    }
  }

  private drawCanvas(): void {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    // Save the context state before applying transformations
    this.ctx.save();
  
    // Translate to the center of the canvas
    const centerX = this.canvas.nativeElement.width / 2;
    const centerY = this.canvas.nativeElement.height / 2;
    this.ctx.translate(centerX, centerY);
  
    // Apply rotation and zoom
    this.ctx.rotate(this.rotationAngle * Math.PI / 180);
    this.ctx.scale(this.scaleFactor * this.initialScaleFactor, this.scaleFactor * this.initialScaleFactor);
  
    // Apply panning (cameraOffset)
    this.ctx.translate(this.cameraOffset.x / this.scaleFactor / this.initialScaleFactor,
                       this.cameraOffset.y / this.scaleFactor / this.initialScaleFactor);
  
    // Draw the image at the center of the canvas
    this.ctx.drawImage(this.image, -this.image.width / 2, -this.image.height / 2);
  
    // Restore the context state after drawing the image
    this.ctx.restore();
  
    // Draw the bounding boxes
    this.drawBoxes();
  }


  private calculateScaleFactor(imageWidth: number, imageHeight: number, canvasWidth: number, canvasHeight: number): number {
    const widthScale = canvasWidth / imageWidth;
    const heightScale = canvasHeight / imageHeight;
    return Math.min(widthScale, heightScale);
  }

  private drawBoxes(): void {
    if (this.getBoxesForCanvas && this.getBoxesForCanvas.propBounds) {
      const [x, y, width, height] = this.getBoxesForCanvas.propBounds.split(',').map(Number);
  
      // Save the context state before applying transformations
      this.ctx.save();
  
      // Translate to the center of the canvas
      const centerX = this.canvas.nativeElement.width / 2;
      const centerY = this.canvas.nativeElement.height / 2;
      this.ctx.translate(centerX, centerY);
  
      // Apply the same rotation and zoom transformations
      this.ctx.rotate(this.rotationAngle * Math.PI / 180);
      this.ctx.scale(this.scaleFactor * this.initialScaleFactor, this.scaleFactor * this.initialScaleFactor);
  
      // Adjust the bounding box coordinates for zoom and panning
      const adjustedX = x - (this.image.width / 2) + this.cameraOffset.x / this.scaleFactor / this.initialScaleFactor;
      const adjustedY = y - (this.image.height / 2) + this.cameraOffset.y / this.scaleFactor / this.initialScaleFactor;
  
      // Draw the bounding box at the adjusted coordinates
      this.ctx.beginPath();
      this.ctx.rect(
        adjustedX,
        adjustedY,
        width, // Width is already scaled by `scaleFactor` during context transformations
        height // Height is already scaled by `scaleFactor` during context transformations
      );
      this.ctx.strokeStyle = this.getBoxesForCanvas.propColor || 'red'; // Default to red if no color is provided
      this.ctx.lineWidth = 2 / (this.scaleFactor * this.initialScaleFactor); // Adjust line width based on zoom level
      this.ctx.stroke();
  
      // Restore the context state after drawing
      this.ctx.restore();
    } else {
      console.log('propBounds is undefined');
    }

  }




  zoomIn(): void {
    this.scaleFactor *= 1.1;
    this.drawCanvas();
  }

  zoomOut(): void {
    this.scaleFactor /= 1.1;
    this.drawCanvas();
  }

  resetZoom(): void {
    // Reset the scale factor to its default value
    this.scaleFactor = 1;
    // If using initialScaleFactor to fit the image initially, you might not need to reset it here
    // this.initialScaleFactor = 1; // Uncomment if necessary

    // Reset the camera (panning) offset to the starting position
    this.cameraOffset = { x: 0, y: 0 };

    // Reset the last mouse position if it's being used for calculations elsewhere
    this.lastMousePosition = { x: 0, y: 0 };
    this.rotationAngle = 0;
    // Redraw the canvas to reflect the reset state
    this.drawCanvas();
  }

  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    this.dragStart.x = event.clientX - this.cameraOffset.x;
    this.dragStart.y = event.clientY - this.cameraOffset.y;
  }

  onMouseUp(event: MouseEvent): void {
    this.isDragging = false;
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      this.cameraOffset.x = event.clientX - this.dragStart.x;
      this.cameraOffset.y = event.clientY - this.dragStart.y;
      this.drawCanvas();
    }
  }

  rotateImage(){
    this.rotationAngle = (this.rotationAngle + 90) % 360;
    this.drawCanvas();
  }

  antiRotateImage(){
    this.rotationAngle = (this.rotationAngle + -90) % 360;
    this.drawCanvas();
  }


}
