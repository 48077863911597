import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationtaskService } from 'src/app/core/services/validationtask/validationtask.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'mapkey',
  templateUrl: './mapkey.component.html',
  styleUrls: ['./mapkey.component.scss'],
  providers: [MessageService]

})
export class MapkeyComponent implements OnInit {
  @Input() getMapKeyData: any;
  @Output() closeSidebar = new EventEmitter();
  @Output() sendSuccesData = new EventEmitter();
  @Output() sendOtherTableSuccesData = new EventEmitter();
  
  fieldList: any;
  tablekeyWord: string;
  selectedFieldList: string;
  otherTableList: any;
  isFieldSelected: boolean = false;
  isKeywordEntered: boolean = false;
  selectedTableList: string;
  constructor(private validationtaskService: ValidationtaskService,
    private messageService: MessageService,
  ) { }
  ngOnInit(): void {
    if (this.getMapKeyData.grid == 'othertable') {
      this.getOtherTableList();
    } else {
      this.getfieldListAll();
    }
  }
  getOtherTableList() {
    const data = {
      customermodel_guid: this.getMapKeyData.customerId
    }
    this.validationtaskService.getTableList(data).subscribe((res) => {
      if (res.flag) {
        this.otherTableList = res.data;
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
    });
  }
  getfieldListAll() {
    console.log(this.getMapKeyData)
    const data: any = {
      customermodel_guid: this.getMapKeyData.customerId,
    }

    if (this.getMapKeyData.grid == 'table') {
      data.table_name = this.getMapKeyData.keyword;
    }
    this.validationtaskService.getFieldList(data).subscribe((res) => {
      if (res.flag) {
        this.fieldList = res.data;
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
    });
  }

  onAppend() {
    this.resetValidation();
    let isValid = true;
    if (this.getMapKeyData.grid == 'table') {
      const data = {
        customermodel_guid: this.getMapKeyData.customerId,
        table_name: this.getMapKeyData.keyword,
        key_word: this.tablekeyWord,
        field_name: this.selectedFieldList,
      }

      if (!this.selectedFieldList) {
        this.isFieldSelected = true;
        isValid = false;
      }

      if (!this.tablekeyWord) {
        this.isKeywordEntered = true;
        isValid = false;
      }
      if (isValid) {
        this.apiCall(data);
      }
    } else if (this.getMapKeyData.grid == 'otherFields') {
      const data = {
        customermodel_guid: this.getMapKeyData.customerId,
        key_word: this.getMapKeyData.keyword,
        field_name: this.selectedFieldList
      }
      if (!this.selectedFieldList) {
        this.isFieldSelected = true;
        this.isKeywordEntered = true;
        isValid = false;
      }
      if (isValid) {
        this.apiCall(data);
      }
    } else {
      const data = {
        customermodel_guid: this.getMapKeyData.customerId,
        table_name: this.selectedTableList,
        key_word: this.tablekeyWord,
        field_name: this.selectedFieldList,
      }
      if (!this.selectedFieldList) {
        this.isFieldSelected = true;
        isValid = false;
      }

      if (!this.tablekeyWord) {
        this.isKeywordEntered = true;
        isValid = false;
      }
      if (isValid) {
        this.apiCall(data);
      }
    }
  }

  apiCall(data) {
    this.validationtaskService.postAppendData(data).subscribe((res => {
      if (res.flag) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
        setTimeout(() => {
          this.closeSidebar.emit(true);
          data.grid = this.getMapKeyData.grid;
          if(data.grid == 'othertable'){
            data.keyword = this.getMapKeyData.keyword;
            data.otherTableName = this.getMapKeyData.otherTableName;
            this.sendOtherTableSuccesData.emit(data);
          }else{
            this.sendSuccesData.emit(data);
          }
        }, 1000);
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
        setTimeout(() => {
          this.closeSidebar.emit(false);
        }, 1000);
      }
    }));
  }

  resetValidation() {
    this.isFieldSelected = false;
    this.isKeywordEntered = false;
  }

  onTableListSelected() {
    console.log(this.selectedTableList)
    const data: any = {
      customermodel_guid: this.getMapKeyData.customerId,
      table_name: this.selectedTableList

    }
    this.validationtaskService.getFieldList(data).subscribe((res) => {
      if (res.flag) {
        this.fieldList = res.data;
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: res.message });
      }
    });
  }
}
