<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">
        <li routerLink="dashboard" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-graph-pie"></i>
            <span data-key="t-dashboard">Dashboard</span>
          </a>
        </li>
        <li routerLink="instances" *ngIf="globals.workspaceActions.includes('Instance_View')"
          routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-folder"></i>
            <span data-key="t-dashboard"> Instances </span>
          </a>
        </li>
        <li routerLink="bots" *ngIf="globals.workspaceActions.includes('Bot_View')" routerLinkActive="mm-active"
          [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="fas fa-robot"></i>
            <span data-key="t-dashboard">Bots</span>
          </a>
        </li>
        <li routerLink="flows" *ngIf="globals.workspaceActions.includes('Flow_View')" routerLinkActive="mm-active"
          [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-swap"></i>
            <span data-key="t-dashboard">Flows</span>
          </a>
        </li>
        <li routerLink="jobs" *ngIf="globals.workspaceActions.includes('Job_View')" routerLinkActive="mm-active"
          [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-user"></i>
            <span data-key="t-dashboard">Jobs</span>
          </a>
        </li>
        <li routerLink="libraries" *ngIf="globals.workspaceActions.includes('Library_View')"
          routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-briefcase"></i>
            <span data-key="t-dashboard"> Libraries </span>
          </a>
        </li>
        <li routerLink="logs" *ngIf="globals.workspaceActions.includes('BotLogs_View')" routerLinkActive="mm-active"
          [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-browser"></i>
            <span data-key="t-dashboard">Logs</span>
          </a>
        </li>
        <li routerLink="configuration" *ngIf="globals.workspaceActions.includes('Configuration_View')"
          routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-toggles"></i>
            <span data-key="t-dashboard"> Configuration </span>
          </a>
        </li>
        <li
          *ngIf="globals.workspaceActions.includes('Queue_View') || globals.workspaceActions.includes('QueuePriority_View') || globals.workspaceActions.includes('QueueCustomStatus_View') "
          routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}"
          [ngClass]="{'mm-active': queuesValue,'mm-active':getClass()}">
          <a (click)="onQueuesList(true)">
            <i class="dripicons-network-1"></i>
            <span data-key="t-dashboard"> Queues </span>
          </a>
        </li>
        <li routerLink="credentialstore" *ngIf="globals.workspaceActions.includes('Credential_View')"
          routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-lock"></i>
            <span data-key="t-dashboard"> Credentials</span>
          </a>
        </li>
        <li routerLink="apikeys" *ngIf="globals.workspaceActions.includes('APIKeys_View')" routerLinkActive="mm-active"
          [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-code"></i>
            <span data-key="t-dashboard"> Api Keys </span>
          </a>
        </li>
        <li routerLink="apptokens" *ngIf="globals.workspaceActions.includes('AppTokens_View')"
          routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="fas fa-coins"></i>
            <span data-key="t-dashboard"> App Tokens </span>
          </a>
        </li>
        <li routerLink="formbuilder" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="mdi mdi-view-list-outline"></i>
            <span data-key="t-dashboard"> Forms </span>
          </a>
        </li>
        <li routerLink="taskcenter" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="bx bx-task"></i>
            <span data-key="t-dashboard"> Task Center </span>
          </a>
        </li>
        <li routerLink="validationtask" *ngIf="globals.workspaceActions.includes('DataExtraction_Manage')" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-photo-group"></i>
            <span data-key="t-dashboard"> Validation</span>
          </a>
        </li>
        <li routerLink="customermodel" *ngIf="globals.workspaceActions.includes('DataExtraction_Manage')" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-photo-group"></i>
            <span data-key="t-dashboard"> Models</span>
          </a>
        </li>
        <li routerLink="ocr" routerLinkActive="mm-active" *ngIf="globals.workspaceActions.includes('OCRExtraction_Manage')" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-photo-group"></i>
            <span data-key="t-dashboard"> OCR</span>
          </a>
        </li>
        <li routerLink="createtable" routerLinkActive="mm-active" *ngIf="globals.workspaceActions.includes('OCRExtraction_Manage')" [routerLinkActiveOptions]="{exact: true}">
          <a (click)="onQueuesList(false)">
            <i class="pi pi-table"></i>
            <span data-key="t-dashboard"> Create Table</span>
          </a>
        </li>
        <li routerLink="settings" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="
        globals.workspaceActions.includes('Workspace_View') ||
        globals.workspaceActions.includes('TenantUsers_View') ||
        globals.workspaceActions.includes('Roles_View')
      ">
          <a (click)="onQueuesList(false)">
            <i class="dripicons-gear"></i>
            <span data-key="t-dashboard"> Settings </span>
          </a>
        </li>
      </ul>
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->