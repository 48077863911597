import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { TokenstorageService } from '../src/app/core/services/token-storage/tokenstorage.service';

let tokenService: TokenstorageService;

function initializeApp(injector: Injector) {
  tokenService = injector.get(TokenstorageService);
}

if (environment.production) {
  enableProdMode();
}

const originalFetch = window.fetch;
window.fetch = async function (input, init) {
  const apiUrl:any = input;

  init = init || {};
  init.headers = init.headers || {};

  // Get the token
  const token = tokenService ? tokenService.getToken() : '';

  const data = window.location.pathname.includes('/formbuilder');

  if (apiUrl.startsWith(environment.serviceurl)) {
    if (data) {
      if (token) {
        const headers = new Headers(init.headers);
        if (!headers.has('Authorization') && token){

          init.headers = {
            ...init.headers,
            'Authorization': `Bearer ${token}`
          };
        }
      }
    }
  }

  return originalFetch(input, init);
};

(function () {
  const originalXMLHttpRequest = window.XMLHttpRequest;
  const originalOpen = XMLHttpRequest.prototype.open;
  const originalSend = XMLHttpRequest.prototype.send;
  const originalSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;
  XMLHttpRequest.prototype.open = function () {
    this._method = arguments[0];
    this._url = arguments[1];

    this._headers = this._headers || {};
    return originalOpen.apply(this, arguments);
  };

  XMLHttpRequest.prototype.setRequestHeader = function (name, value) {
    // Store the headers in the _headers object
    this._headers[name] = value;
    return originalSetRequestHeader.call(this, name, value);
  };


  XMLHttpRequest.prototype.send = function () {
    if (this._url.startsWith(environment.serviceurl)) {
      const token = tokenService ? tokenService.getToken() : '';
      if (!this._headers) {
        this._headers = {};
      }
      const data = window.location.pathname.includes('/formbuilder');
      if (data) {
        if (token) {
          if(!this._headers['Authorization']){
            this.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        }
      }
    }
    return originalSend.apply(this, arguments);
  };
})();

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleRef => {
    const injector = moduleRef.injector;
    initializeApp(injector);  // Initialize the token service
  })
  .catch(err => console.error(err));


const originalCreateElement = document.createElement;
document.createElement = function(tagName: string) {
  const element = originalCreateElement.call(document, tagName);
  if (tagName.toLowerCase() === 'style') {
    element.setAttribute('nonce', 'automatrdynamicstyle');
  }
  return element;
};