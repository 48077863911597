import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarOpen = new BehaviorSubject<boolean>(false);

  // Observable for sidebar state
  public sidebarOpen$ = this.sidebarOpen.asObservable();

  constructor() {}

  public setSidebarState(isOpen: boolean): void {
    this.sidebarOpen.next(isOpen);
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
}
