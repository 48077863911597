import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDatetime'
})
export class CustomDatetimePipe extends DatePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      let dt = new Date(value);
      
      let formattedDate = dt.toLocaleDateString("en-US", { year: 'numeric', month: 'numeric', day: 'numeric' });
      
      let hours = dt.getHours();
      let minutes = dt.getMinutes();
      
      let formattedHours = hours === 24 ? '00' : hours.toString().padStart(2, '0');
      let formattedMinutes = minutes.toString().padStart(2, '0');

      let formattedTime = `${formattedHours}:${formattedMinutes}`;

      return `${formattedDate} ${formattedTime}`;
    } else {
      return "";
    }
  }
}
