import { Component, OnInit } from '@angular/core';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer Component
 */
export class FooterComponent implements OnInit {

   // set the currenr year
   year: number = new Date().getFullYear();
   public version: string = packageJson.version;

  constructor() { }

  ngOnInit(): void {
  }

}
