import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { delay, map } from 'rxjs/operators';
import { Globals } from 'src/app/globals/globals';

@Injectable()
export class WorkspaceService {
  constructor(public http: HttpClient, public globals: Globals) { }

  public getWorkspaces(seachData): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_View')) {
      return this.http.post(
        environment.serviceurl + '/Workspace/search',
        seachData
      );
    } else {
      return from([]);
    }
  }

  public getMyWorkspaces(): Observable<any> {
    return this.http.get(environment.serviceurl + '/Workspace/MyWorkspaces');
  }
  public getMyWorkspaceActions(): Observable<any> {
    const workspaceId  = this.globals.WorkSpaceID;
    return this.http.get(
      environment.serviceurl + '/Workspace/CurrentWorkspaceActions/' + workspaceId
    );
  }
  public setWorkspace(data): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_Create')) {
      return this.http.post(environment.serviceurl + '/Workspace', data);
    } else {
      return from([]);
    }
  }

  public getWSUsers(data): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_UsersView')) {
      return this.http.post(
        environment.serviceurl + '/Workspace/Users/search',
        data
      );
    } else {
      return from([]);
    }
  }
  // public deleteWorkspaceUser(user_guid): Observable<any> {
  //   return this.http.delete(environment.serviceurl+'/TenantUser/'+ user_guid)
  // }

  public deleteWorkspaceUser(ws_guid, user_guid): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_UsersDelete')) {
      return this.http.delete(environment.serviceurl + '/Workspace/Users/' + ws_guid + '/' + user_guid);
    } else {
      return from([]);
    }
  }

  public setUser(data): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_UsersCreate') || this.globals.workspaceActions.includes('Workspace_UsersEdit')) {
      return this.http.post(environment.serviceurl + '/Workspace/Users', data);
    } else {
      return from([]);
    }
  }
  public getExistingTenantUsers(data): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_UsersView')) {
      return this.http.get(
        environment.serviceurl + '/Workspace/TenantUsers',
        data
      );
    } else {
      return from([]);
    }
  }
  public getUserOptions(): Observable<any> {
    if (this.globals.workspaceActions.includes('Workspace_UsersView')) {
      let par: HttpParams = new HttpParams().set("silentServerFetch", "1");
      return this.http.get(environment.serviceurl + '/Workspace/TenantUsers', { params: par });
    } else {
      return from([]);
    }
  }

  public setUserTenant(data): Observable<any> {
    if (this.globals.workspaceActions.includes('TenantUsers_Create') || this.globals.workspaceActions.includes('TenantUsers_Edit')) {
      return this.http.post(environment.serviceurl + '/TenantUser', data);
    } else {
      return from([]);
    }
  }

}
